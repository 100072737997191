$(document).ready(function($) {
	// link to top
	var offset = 700;
	var duration = 500;
	jQuery(window).scroll(function() {
		if (jQuery(this).scrollTop() > offset) {
			jQuery('#back-top').fadeIn(duration);
		} else {
			jQuery('#back-top').fadeOut(duration);
		}
	});

	jQuery('#back-top').click(function(event) {
		event.preventDefault();
		jQuery('html, body').animate({scrollTop: 0}, duration);
		return false;
	});

	$(document).on('click', '.btn-loading', function(){
		var obj = $(this);
		setTimeout(function(){
			obj.button('loading');
		}, 50);
	});

	$(document).on('submit', 'form', function(){
		$('.btn-submit-loading').button('loading');
	});


	// delete by ajax btn
	$(document).on('click', '.btn-delete', function(){
		var obj = $(this);
		var url = obj.attr('data-url');

		if(confirm('Delete Confirm??')==true){

			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				success: function(){
					obj.parents('tr').remove();
				},
				beforeSend: function(){
					obj.button('loading');
				},
				complete: function(){
					obj.button('reset');
				},
				error: function(msg){
					alert(msg.responseJSON);
				}
			});

		}
	});

	// set cover
	$(document).on('click', '.setcover', function(){
		var obj = $(this);
		var url = obj.attr('data-url');
		obj.button('reset');

		if(confirm('คุณต้องการตั้งรูปนี้เป็นรูปปก ใช่หรือไม่??')===true){

			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				beforeSend: function(){
					// obj.button('loading');
				},
				complete: function(){
					// obj.button('reset');
				},
				success: function(){
					$('.setcover').removeClass('disabled').find('.cover-text').text('Set Cover');
					obj.addClass('disabled').find('.cover-text').text('Is Cover');
					// obj.parents('tr').remove();
				},
				error: function(msg){
					alert(msg.responseJSON);
				}
			});

		}
	});

	//upload image
	$(document).on('click', '#imageSubmit', function(e){
		var obj = $(this);
		var url = obj.attr('data-url');
		var formData = new FormData($("#post-form")[0]);

		$.ajax({
			url: url,
			type: "POST",
			data:  formData,
			contentType: false,
			cache: false,
			processData:false,
			beforeSend: function(){
				obj.button('loading');
			},
			success: function(data){
				$('.slider-list').append(data);
				$('#uploadFormLayer .inputFile').val('');
				alert('อัพโหลดรูปภาพเรียบร้อยแล้ว');
			},
			error: function(resp){
				alert(resp.responseText);
			},
			complete: function(){
				obj.button('reset');
			}
		});

	});

	// delete image by ajax btn
	$(document).on('click', '.btn-img-delete', function(){
		var obj = $(this);
		var url = obj.attr('data-url');

		if(confirm('Delete Confirm??')==true){

			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				success: function(){
					obj.parents('.img-thumbnail').parent().remove();
				},
				beforeSend: function(){
					obj.button('loading');
				},
				complete: function(){
					obj.button('reset');
				},
				error: function(msg){
					alert(msg.responseJSON);
				}
			});

		}
	});

});